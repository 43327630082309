import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { SliceZone } from "../components/pageComponents"

const GeneralPage = ({ data: { prismicGeneralPage } }) => {
  const { page_title, body } = prismicGeneralPage.data
  return (
    <Layout>
      <SEO title={page_title.text}></SEO>
      {body && <SliceZone slices={body} />}
    </Layout>
  )
}

export default GeneralPage

export const pageQuery = graphql`
  query pageBySlug($uid: String!) {
    prismicGeneralPage(uid: { eq: $uid }) {
      data {
        page_title {
          text
        }
        body {
          ... on PrismicGeneralPageBodyPageBanner {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              heading {
                text
              }
            }
          }
          ... on PrismicGeneralPageBodyCenteredImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              horizontal_wrap
            }
          }
          ... on PrismicGeneralPageBodySingleColumnText {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicGeneralPageBodyTwoColumnText {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicGeneralPageBodyCenteredText {
            id
            slice_type
            primary {
              heading {
                text
              }
              centered_text {
                html
              }
            }
          }
          ... on PrismicGeneralPageBodyStandardContent {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              link {
                uid
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              reverse_layout
            }
          }
          ... on PrismicGeneralPageBodyFeatureContent {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              link {
                uid
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              reverse_layout
            }
          }
          ... on PrismicGeneralPageBodyWineBlock {
            id
            slice_type
            primary {
              bottle_shot {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              background_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              heading {
                text
              }
              description {
                html
              }
              enquire_link {
                url
                uid
                type
                link_type
              }
              reverse_layout
            }
          }
          ... on PrismicGeneralPageBodyCenteredQuote {
            id
            slice_type
            primary {
              centered_quote {
                text
              }
            }
          }
          ... on PrismicGeneralPageBodyEditorialOne {
            id
            slice_type
            primary {
              image_left {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              image_right {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text {
                html
              }
              reverse_layout
            }
          }
          ... on PrismicGeneralPageBodyEditorialTwo {
            id
            slice_type
            primary {
              image_left {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              image_right {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text_top {
                html
              }
              text_bottom {
                html
              }
            }
          }
          ... on PrismicGeneralPageBodyMembershipTable {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              title {
                text
              }
              text {
                html
              }
              price {
                text
              }
            }
          }
          ... on PrismicGeneralPageBodyContactForm {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicGeneralPageBodyMembershipTeaser {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              page {
                uid
              }
            }
          }
          ... on PrismicGeneralPageBodySpacer {
            id
            slice_type
            primary {
              size
            }
          }
        }
      }
    }
  }
`
